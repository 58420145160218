<template>
  <div class="account-plan-submenu-wrapper">
    <b-row class="submenu-container dark mb-4" no-gutters>
      <b-col md="auto">
        <b-link href="javascript:void(0)" :class="{ disabled: active === 'overview' }" @click.prevent="redirectTab('overview')">
          {{ $t(translationPath + 'overview') }}
        </b-link>
        <span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link href="javascript:void(0)" :class="{ disabled: active === 'category-group' }" @click.prevent="redirectTab('category-group')">
          {{ $t(translationPath + 'advanced_filter.category_group') }}
        </b-link>
        <span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link href="javascript:void(0)" :class="{ disabled: active === 'category' }" @click.prevent="redirectTab('category')">
          {{ $t(translationPath + 'categories') }}
        </b-link>
        <span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link href="javascript:void(0)" :class="{ disabled: active === 'settings' }" @click.prevent="redirectTab('settings')">
          {{ $t(translationPath + 'settings.name') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AccountPlanSubmenu from '../account-plan-v2/AccountPlanSubmenu'
export default {
  name: 'CashForecastSubmenu',
  extends: AccountPlanSubmenu,
  data () {
    return {
      translationPath: 'common.',
      baseUrl: '/reports/other/cash-forecast/',
      to: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
</style>
