<template>
  <b-container class="reports cash-forecast bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col>
        <b-row no-gutters>
          <b-col>
            <h1>{{ $t('reports.other._common.cashforecast_report')}}</h1>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col>
            <CashForecastSubmenu :active="activeTab"/>
          </b-col>
        </b-row>
        <template v-if="activeTab === 'overview'">
          <overview-tab/>
        </template>
        <template v-else-if="activeTab === 'category-group'">
          <category-group-tab/>
        </template>
        <template v-else-if="activeTab === 'category'">
          <category-tab/>
        </template>
        <template v-else-if="activeTab === 'settings'">
          <settings-tab/>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CashForecastSubmenu from './CashForecastSubmenu'
import {
  OverviewTab,
  SettingsTab,
  CategoryGroupTab,
  CategoryTab
} from './Tabs'

export default {
  name: 'CashForecast',
  components: {
    CashForecastSubmenu,
    OverviewTab,
    SettingsTab,
    CategoryGroupTab,
    CategoryTab
  },
  data () {
    return {
      activeTab: 'overview'
    }
  },
  created () {
    const { tab } = this.$route.params
    if (tab) {
      this.activeTab = tab
    }
  },
  beforeRouteUpdate (to, from, next) {
    const { tab } = to.params
    if (tab) {
      this.activeTab = tab
    }
    next()
  }
}
</script>

<style lang="scss">
.cash-forecast {
  width: 100%;
  display: inline-block !important;
  tbody {
    td {
      &:nth-child(1) {
        @media screen and (min-width: 992px){
          width: 130px;
        }
      }
    }
  }
}
.main-gradient-content-card {
  position: relative;
  top: 0;
}
</style>
