<template>
  <div class="cash-forecast OverviewTab">
    <b-row no-gutters>
      <b-col> </b-col>
      <b-col md="4">

      </b-col>
    </b-row>
    <b-row no-gutters class="mt-4">
      <b-col>
        <b-card
          no-body
          class="main-gradient-content-card borderlight px-4 py-3 mx-xl-auto"
        >
          <b-row no-gutters>
            <h3 class="mb-3 font-weight-bolder">{{$t('common.overview')}}</h3>
          </b-row>
          <b-row no-gutters>
            <b-table
              class="spirecta-simple-table assets-table"
              :items="records"
              :fields="fields"
              :emptyText="$t('common.no_data')"
              stacked="md"
              :busy="busy"
              show-empty
            >
              <!--loader-->
              <template v-slot:table-busy>
                <loader />
              </template>
            </b-table>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'

export default {
  name: 'OverviewTab',
  components: {
    Loader
  },
  data () {
    return {
      start_date: null,
      end_date: null,
      records: [],
      busy: false
    }
  },
  computed: {
    fields () {
      return [
        { key: 'date', label: this.$t('common.date'), sortable: true, class: 'td-date text-left text-md-left dateRow' },
        { key: 'title', label: this.$t('common.title'), sortable: false, class: 'text-left font-weight-bolder' },
        { key: 'amount', label: this.$t('common.amount'), sortable: false, class: 'td-amount text-left text-md-right amountRow' }
      ]
    }
  },
  methods: {
    applyFilter () {
      console.log('APPLY FILTER')
    },
    handleValueChange (value) {
      console.log('VALUE ::: ', value)
    }
  }
}
</script>

<style>

</style>
